const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel', 'Option'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_tire_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'wheel_tire_diameter'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';

globalThis.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );
  const compareBtn = window.document.body.querySelector('.cm_card-compare_link');
  const compareCount = window.document.body.querySelector('.cm_card-compare_count');
  if (!compareBtn) {
    return;
  }

  compareCount.textContent = productsIdsToCompare.length;
  compareBtn.classList.toggle('show', productsIdsToCompare.length > 1);
  compareBtn.href = `/compare/${productsIdsToCompare.join('/')}/`;
};

function getLocalPreselection(pageType, defaults) {
  if (defaults.termFromBreadcrumbs === 'Tires') {
    return;
  }
  if (defaults.termFromBreadcrumbs.startsWith('Tires')) {
    const trimmedTermFromBreadcrumbs = defaults.termFromBreadcrumbs.replace('Tires>', '');
    return [
      {
        field: 'category',
        term: trimmedTermFromBreadcrumbs,
        treeLevel: trimmedTermFromBreadcrumbs.split('>').length - 1,
      },
    ];
  }
  return defaults.getter(pageType);
}

function responseHandler(response) {
  globalThis.Convermax.VinParsedVehicle = response.Extra.VinParsedVehicle;
  globalThis.Convermax.VinEntered = response.Extra.VinEntered;
  return response;
}

export default {
  platform: 'bigcommerce',
  getLocalPreselection,
  responseHandler,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    categorySelectionPageRedirect: true,
    getFitmentSearchTitle: () =>
      window.location.pathname === categorySelectionPageUrl
        ? 'Categories'
        : window.location.pathname === brandSelectionPageUrl
          ? 'Brands'
          : 'Tires',
    isVehicleSelectionIsolated: true,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset', 'tire_load_index'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { replace: '#main-content .page-content' },
      template: 'SearchPage',
      visibleIf: () => window.location.pathname === '/search/',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#product-listing-container',
      template: 'MainContent',
      visibleIf: () =>
        window.location.pathname !== categorySelectionPageUrl &&
        window.location.pathname !== brandSelectionPageUrl,
    },
    {
      name: 'CategoryFacetPanel',
      type: 'SearchPage',
      location: '.categoryPage .sidebarcategories',
      template: 'FacetPanelContainer',
      ignoreFields,
      visibleIf: () =>
        window.location.pathname !== categorySelectionPageUrl &&
        window.location.pathname !== brandSelectionPageUrl,
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    { name: 'SearchResult' },
    {
      name: 'VehicleInfo',
      initCollapsed: true,
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'SearchBox',
      location: 'header .searchForm',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { insertAfter: 'header .humbugermenu' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '.cm_tab-content.cm_vehicle-search',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 650,
    },
    {
      name: 'HomeTirePanel_tab',
      type: 'RequestPanel',
      location: '.cm_tab-content.cm_tire-search',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      selectFields: [...tiresFields, 'brand_name'],
    },
    {
      name: 'HomeSearchBox_tab',
      type: 'SearchBox',
      location: { selector: '.cm_tab-content.cm_vin-search', class: 'cm_search-box-root__tab' },
      template: 'SearchBox',
      disableDropdown: true,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: { firstChildOf: '.headerMain .headerCart' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: { firstChildOf: '.headerMain .headerCartMob' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { lastChildOf: '.productView-details.product-data' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: { replace: '.categorypageInner' },
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: { replace: '.categorypageInner' },
      template: 'facetTiles/alphabeticalContainer',
      view: 'columns',
      visibleIf: () => window.location.pathname === brandSelectionPageUrl,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: ['Universal'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
